import './App.css';
import Navigation from './Components/Navigation';
import Login from './Components/Login';
import AuthProvider from './hooks/AuthProvider';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './Router/Route';
import LoggedInRoute from './Router/LoggedInAlready';
import Dashboard from './Components/Dashboard';

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            {/* Protect the root route by redirecting to the login page if the token doesn't exist */}
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<Dashboard />} />
            </Route>

            {/* If the user tries to access the login page when already signed in, redirect them back to the home page. */}
            <Route element={<LoggedInRoute />}>
              <Route path="/login" element={<Login />} />
            </Route>

            {/* Other routes */}

          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;

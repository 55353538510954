import React, { useEffect } from "react";
import { useContext, createContext, useState } from "react";
import { useAuth } from "../hooks/AuthProvider";

const Logout = () => {

    const auth = useAuth();

    return (
        <div>
            <button onClick={() => auth.logOut()} className="btn-submit btn btn-danger">Logout</button>
        </div>
    );
}

export default Logout;
import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/AuthProvider";
import Navigation from "./Navigation";
import CrisisCommunicationEmail from "../Emails/CrisisCommunicationEmail";
import Logout from "./Logout";

const Dashboard = () => {
    const auth = useAuth();

    const [input, setInput] = useState({
        messagebody: "",
        messagesubject: "Important Communication from Graybar Canada"
    });

    const handleSendEmail = (e) => {
        // disable default form submit function
        e.preventDefault();
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value
        }));
    };


    return (
        <div className="container-fluid">
            <div className="row ms-5">

                <div className="email-submission-form me-3 col-12 col-lg-5">
                    <Navigation />

                    <div className="border-top border-2 mt-3 pt-3">
                        <h3>Send an email</h3>

                        <form onSubmit={handleSendEmail}>
                            <div className="d-flex">
                                <div className="flex-fill me-5">
                                    <label htmlFor="from-email" className="form-label fw-bold">From email address</label>
                                    <input className="form-control"
                                        type="text"
                                        id="from-email"
                                        name="from-email"
                                        placeholder="from@mailbox.com"
                                    />
                                </div>
                                <div className="flex-fill">
                                    <label htmlFor="message-subject" className="form-label fw-bold">Message Subject</label>
                                    <input className="form-control"
                                        type="text"
                                        id="messagesubject"
                                        name="messagesubject"
                                        onChange={handleInput}
                                        placeholder="Important Communication from Graybar Canada"
                                        value={input.messagesubject}
                                    />
                                </div>
                            </div>

                            <div className="mt-4">
                                <label htmlFor="to-email" className="form-label fw-bold">To email addresses (Bcc)</label>
                                <textarea className="form-control form-input-textfield"
                                    id="to-email"
                                    name="to-email"
                                    placeholder="Enter email addresses separated by comma or semnicolon..."
                                />
                            </div>

                            <div className="mt-4">
                                <label htmlFor="message-body" className="form-label fw-bold">Message Body</label>
                                <textarea className="form-control form-input-textfield"
                                    id="messagebody"
                                    name="messagebody"
                                    onChange={handleInput}
                                    placeholder="Type a message here..."
                                />
                            </div>
                            <div className="smtp-server-select mt-3">
                                <select id="smtp-server" name="smtp-server" className="form-select">
                                    <option disabled>Select an SMTP Server...</option>
                                    <option value={"test"}>Postmark</option>
                                </select>
                            </div>


                            <div className="d-grid gap-2 col-6 mx-auto mb-3 mt-5">
                                <button className="btn-submit btn btn-primary">Send Email</button>
                            </div>

                            <Logout/>

                        </form>

                    </div>
                </div>

                <div className="border-start border-2 ps-3 col-12 col-lg-5">
                    <h3 className="border-bottom border-3 mb-5">Live Preview</h3>
                    {/* <iframe src={"data:text/html,"+encodeURIComponent(content)}/> */}
                        <CrisisCommunicationEmail body={input.messagebody} subject={input.messagesubject} />
                </div>
            </div>

        </div>
    );
};

export default Dashboard;
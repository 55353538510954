import { useState } from "react";
import { useAuth } from "../hooks/AuthProvider";

function Login() {
    const [input, setInput] = useState({
        username: "",
        password: ""
    });

    const auth = useAuth();

    const handleSubmitEvent = (e) => {
        e.preventDefault();

        console.log(input);
        if (input.username !== "" && input.password !== "") {
            auth.loginAction(input);
        }else{
            alert("Invalid input");

        }

    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    return (
        <section className="d-flex justify-content-center align-items-center">
            <div className="login-form border border-3 d-flex flex-column mt-5 rounded-3 bg-light">
                <img src={process.env.PUBLIC_URL + '/gbclogo.png'} alt="Graybar Canada Logo" width={200} className="align-self-end p-2" />
                <h3 className="m-3">Login to the GBC Crisis Communication platform</h3>
                <form className="" onSubmit={handleSubmitEvent}>
                    <div className="form_control m-3">
                        <label htmlFor="username" className="form-label">Username:</label>
                        <input className="form-control"
                            type="text"
                            id="username"
                            name="username"
                            placeholder="Username"
                            onChange={handleInput}
                            aria-describedby="username-help" />
                        <div id="username-help" className="sr-only">
                            Please enter a valid username. It must contain at least 6 characters.
                        </div>
                    </div>
                    <div className="form_control m-3">
                        <label htmlFor="password" className="form-label">Password:</label>
                        <input className="form-control"
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Password"
                            onChange={handleInput} />
    
                    </div>
                    <div id="user-password" className="sr-only">
                        Your password should be more than 6 character
                    </div>
                    <div className="d-grid gap-2 col-6 mx-auto mb-3 mt-5">
                        <button className="btn-submit btn btn-primary">Login</button>
                    </div>
                </form>
    
            </div>
        </section>
        );
};

export default Login;


function Navigation(){

    return (
        <nav className="">
            <div className="pt-2">
                <h2>Send Crisis Communication</h2>
                <p>The following form should only be used when neccessary. This form can be used to send large batches of Crisis Communications to employees and partners.</p>
            </div>
        </nav>
    );
}


export default Navigation;
import {
  Body,
  Button,
  Container,
  Column,
  Head,
  Heading,
  Html,
  Img,
  Preview,
  Row,
  Section,
  Text,
} from "@react-email/components";
import * as React from "react";

export const CrisisCommunicationEmail = ({
  subject,
  body
}) => {

  body = body.split("\n");
  console.log(body);

  var count = 0

  const bodyFormatted = body.map((item) =>
    <Text style={paragraph} key={count++}>
      {item}
    </Text>
  );

  //TODO: The image source for the logo should put moved into a separate static resource CDN as this 
  //      application will be restricted to be only accessible internally

  return (
    <Html>
      <Head />
      <Preview>Important Communication from Graybar Canada</Preview>
      <Body style={main}>
        <Container>
          <Section style={logo}>
            <Img src={process.env.PUBLIC_URL + '/gbclogo.png'} alt="Graybar Canada Logo" />
          </Section>

          <Section style={content}>

            <Row style={{ ...boxInfos, paddingBottom: "0" }}>
              <Column>
                <Text style={paragraph}>
                  {subject}
                </Text>
              </Column>
            </Row>

            <Row style={{ ...boxInfos, paddingBottom: "0" }}>
              <Column>
                {
                  bodyFormatted
                }
              </Column>
            </Row>

            <Row style={{ ...footer, paddingTop: "0" }}>
              <Column style={footerlogo} colSpan={2}>
                <Img src={process.env.PUBLIC_URL + '/gbclogo.png'} alt="Graybar Canada Logo" />
              </Column>
              <Column style={footerText} colSpan={2}>
                <Text style={footerTextLogo}>Graybar Canada</Text>
                <Text style={footerAddress}>3600 Joseph Howe Drive, Halifax, NS B3L 4H7</Text>
              </Column>
            </Row>

          </Section>

          <Text
            style={{
              textAlign: "center",
              fontSize: 12,
              color: "rgb(0,0,0, 0.7)",
            }}
          >
          </Text>
        </Container>
      </Body>
    </Html>
  );
};

export default CrisisCommunicationEmail;

const main = {
  backgroundColor: "#fff",
  fontFamily:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
  maxWidth: "600px"
};

const paragraph = {
  fontSize: 16,
};

const logo = {
  backgroundColor: "#f4f4f4",
  textAlign: "right",
  padding: "200px",
};

const content = {
  border: "1px solid rgb(0,0,0, 0.1)",
  borderRadius: "3px",
  overflow: "hidden",
};

const boxInfos = {
  padding: "20px",
};

const footer = {
  backgroundColor: "#f4f4f4",
  padding: "20px",
  width: "100%",
  paddingRight: "10"
}

const footerlogo = {
  verticalAlign: "middle",
  width: "150px"
}

const footerText = {
  borderLeft: "#636466 1px solid",
  fontFamily: "Calibri,Arial; font-size:14px",
  paddingLeft: "14px",
  verticalAlign: "top",
  fontWeight: "Bold"
}

const footerTextLogo = {
  fontFamily: "Calibri,Arial",
  fontSize: "16px",
  lineHeight: "16px"
}

const footerAddress = {
  color: "#636466",
  fontFamily: "Calibri,Arial",
  fontSize: "14px",
  lineHeight: "22px"
}